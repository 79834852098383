import dynamic from 'next/dynamic'

import { Container, Row, Column } from './base/gridview'

const Button = dynamic(() => import('./base/button'))
const Heading = dynamic(() => import('./base/heading'))
const Html = dynamic(() => import('./base/html'))
const Section = dynamic(() => import('./base/section'))
const Image = dynamic(() => import('./base/image'))
const Video = dynamic(() => import('./base/video'))

const Header = dynamic(() => import('./widgets/header'))
const Hero = dynamic(() => import('./widgets/hero'))
const Footer = dynamic(() => import('./widgets/footer'))
const ContactAdresses = dynamic(() => import('./widgets/contact-adresses'))
const HeadlineWorks = dynamic(() => import('./widgets/headline-works'))
const WorkHero = dynamic(() => import('./widgets/work-hero'))
const WorksRefs = dynamic(() => import('./widgets/works-refs'))
const WorkList = dynamic(() => import('./widgets/work-list'))
const OurPartners = dynamic(() => import('./widgets/our-partners'))
const Detail = dynamic(() => import('./widgets/detail'))
const SocialInfos = dynamic(() => import('./widgets/social-infos'))
const Awards = dynamic(() => import('./widgets/awards'))
const Team = dynamic(() => import('./widgets/team'))
const HomeWorkText = dynamic(() => import('./widgets/home-work-text'))
const Careers = dynamic(() => import('./widgets/careers'))
const WorkDetail = dynamic(() => import('./widgets/work-detail'))
const AboutText = dynamic(() => import('./widgets/about-text'))
const DetailVideo = dynamic(() => import('./widgets/detail-video'))
const Popup = dynamic(() => import('./widgets/popup'))
const ImageBanner = dynamic(() => import('./widgets/image-banner'))

export default function getter(name: string) {
  switch (name) {
    case 'container':
      return Container
    case 'heading':
      return Heading
    case 'html':
      return Html
    case 'row':
      return Row
    case 'column':
      return Column
    case 'button':
      return Button
    case 'image':
      return Image
    case 'section':
      return Section
    case 'video':
      return Video

    case 'bukafa-header':
      return Header
    case 'bukafa-hero':
      return Hero
    case 'bukafa-footer':
      return Footer
    case 'bukafa-contact-adresses':
      return ContactAdresses
    case 'bukafa-headline-works':
      return HeadlineWorks
    case 'bukafa-work-hero':
      return WorkHero
    case 'bukafa-works-refs':
      return WorksRefs
    case 'bukafa-work-list':
      return WorkList
    case 'bukafa-our-partners':
      return OurPartners
    case 'bukafa-detail':
      return Detail
    case 'bukafa-social-infos':
      return SocialInfos
    case 'bukafa-awards':
      return Awards
    case 'bukafa-team':
      return Team
    case 'bukafa-home-work-text':
      return HomeWorkText
    case 'bukafa-careers':
      return Careers
    case 'bukafa-work-detail':
      return WorkDetail
    case 'bukafa-about-text':
      return AboutText
    case 'bukafa-detail-video':
      return DetailVideo
    case 'bukafa-popup':
      return Popup
    case 'bukafa-image-banner':
      return ImageBanner
  }
}
